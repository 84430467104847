/** @jsx jsx */
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import SVG from 'react-inlinesvg';
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';
import playButton from '../../../../static/svg/play-button.svg';
import closeBtn from '../../../../static/svg/close-button.svg';
import { getVideoType } from '../../../utils/videoUtils';
import {
  colors,
  durations,
  rgba,
  unitConverter as uc,
} from '../../../styles/base';
import cornerstoneLogo from '../../../../static/logo/CSOD_Avatar_white.svg';

const csodLogoCss = css`
  position: relative;
  fill: ${colors.primary};

  svg {
    width: 100%;
    height: ${uc(`400px`)};
  }

  path {
    fill: ${colors.primary};
  }
`;

const InlineVideo = ({ image, updateState, video, videoRef }) => {
  const [state, setState] = useState({ open: false });

  if (typeof videoRef === 'undefined') {
    videoRef = useRef(null); // eslint-disable-line no-param-reassign
  }

  const videoModalRef = useRef(null);
  const overlayRef = useRef(null);

  const overlayCss = css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: block;
    background-color: rgba(${rgba(colors.black, 0.8)});
    visibility: hidden;
    opacity: 0;
    transition: all ${durations.medium} ease-in-out;

    &.open {
      visibility: visible;
      opacity: 1;
    }
  `;

  const modalWrapperCss = css`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 101;
    display: block;
    width: 75%;
    background-color: ${colors.black};
    transform: translateY(-50%) translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: all ${durations.medium} ease-in-out;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 101;
      width: 100%;
      height: 100%;
      pointer-events: auto;
    }

    &.open {
      visibility: visible;
      opacity: 1;
    }
  `;

  const modalCss = css`
    position: relative;
    height: 0;
    padding-top: ${uc('25px')};
    padding-bottom: 56.25%; /* 16:9 */
  `;

  const closeBtnCss = css`
    position: absolute;
    top: ${uc('20px')};
    right: ${uc('20px')};
    z-index: 101;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    opacity: 0.6;
    transition: all ${durations.medium} ease-in-out;

    svg {
      width: ${uc('30px')};
      height: ${uc('30px')};

      .st0 {
        stroke: white;
      }
    }

    &:active,
    &:focus,
    &:hover {
      opacity: 0.8;
    }
  `;

  const imageWrapperCss = css`
    position: relative;
    background: ${image ? colors.transparent : colors.darkGray.three};
    cursor: pointer;

    img {
      z-index: 0;
    }

    &:active,
    &:focus,
    &:hover {
      .play-button {
        transform: translateX(-50%) translateY(-50%) scale(1.2);
      }
    }
  `;

  const playButtonCss = css`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: ${uc('100px')};
    height: ${uc('100px')};
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center;
    transition: all ${durations.fast} ease-in-out;

    svg {
      .st0 {
        fill: white;
        stroke: none;
      }
    }
  `;

  /**
   * Clone the modal divs and prepend them to body so that the z-index
   * values aren't affected by their parents.
   */
  const cloneModal = () => {
    const newVideoModal = videoModalRef.current.cloneNode(true);
    const newOverlayModal = overlayRef.current.cloneNode(true);
    const body = document.querySelector('body');

    // only autoplay on click
    newVideoModal.innerHTML = newVideoModal.innerHTML.replace(
      'autoplay=0',
      'autoplay=1'
    );

    body.prepend(newVideoModal);
    body.prepend(newOverlayModal);

    newOverlayModal.classList.add('open');
    newVideoModal.classList.add('open');

    newOverlayModal.addEventListener('click', () => {
      body.removeChild(newOverlayModal);
      body.removeChild(newVideoModal);
      setState({ open: false });
      if (typeof updateState !== 'undefined') {
        updateState({ open: false });
      }
    });
  };

  /**
   * Open the modal window.
   */
  const openModal = () => {
    setState({ open: true });
    if (typeof updateState !== 'undefined') {
      updateState({ open: true });
    }
    cloneModal();
  };

  // container css classes
  const classes = ['inline-video', `${getVideoType(video.url)}-video`];

  if (state.open) {
    classes.push('open');
  }
  // override autoplay settings coming from video embed stored in Sanity
  const noAutoplayEmbedCode = video.embedCode.replace(
    /autoplay=1/g,
    'autoplay=0'
  );

  return (
    <div className={classes.join(' ')} ref={videoRef}>
      <div className="modal-overlay" css={overlayCss} ref={overlayRef}>
        <button
          className="close-button"
          data-cy="close-button"
          type="button"
          css={closeBtnCss}
        >
          <SVG src={closeBtn} />
        </button>
      </div>
      <div css={modalWrapperCss} className="modal" ref={videoModalRef}>
        <div
          css={modalCss}
          /* eslint-disable react/no-danger */
          dangerouslySetInnerHTML={{
            __html: noAutoplayEmbedCode,
          }}
          /* eslint-enable react/no-danger */
        />
      </div>
      <div // eslint-disable-line jsx-a11y/no-static-element-interactions
        className="image-wrapper"
        css={imageWrapperCss}
        onClick={openModal}
        onKeyUp={openModal}
      >
        {image && (
          <Image
            src={sanityImage(image)
              .auto('format')
              .width(800)}
          />
        )}
        {!image && (
          <span css={csodLogoCss}>
            <SVG src={cornerstoneLogo} />
          </span>
        )}
        <span className="play-button" css={playButtonCss}>
          <SVG src={playButton} />
        </span>
      </div>
    </div>
  );
};

InlineVideo.propTypes = {
  video: PropTypes.shape({
    embedCode: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({}).isRequired,
  videoRef: PropTypes.shape({}),
  updateState: PropTypes.func,
};

InlineVideo.defaultProps = {
  videoRef: undefined,
  updateState: undefined,
};

export default InlineVideo;
