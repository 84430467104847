/**
 * Get the video type from the URL. It will either be YouTube or Vidyard.
 */
export const getVideoType = url => {
  if (!url) {
    return null;
  }
  let type = 'youtube';
  if (url.match('vidyard') !== null) {
    type = 'vidyard';
  }
  return type;
};

/**
 * Get the iframe object
 * @param ref
 *  Object reference created with useRef
 * @return {Node}
 */
export const getIframe = ref => ref.querySelectorAll('iframe')[0];

/**
 * Play the youtube video in the iframe.
 * @param ref
 *  Object reference created with useRef
 */
export const playYoutubeVideo = ref => {
  const iframe = getIframe(ref);
  iframe.contentWindow.postMessage(
    '{"event":"command","func":"playVideo","args":""}',
    '*'
  );
};

/**
 * Pause the youtube video in the iframe.
 * @param ref
 *  Object reference created with useRef
 */
export const pauseYoutubeVideo = ref => {
  const iframe = getIframe(ref);
  iframe.contentWindow.postMessage(
    '{"event":"command","func":"pauseVideo","args":""}',
    '*'
  );
};

/**
 * Play the vidyard video in the iframe.
 * @param ref
 *  Object reference created with useRef
 */
export const playVidyardVideo = ref => {
  const iframe = getIframe(ref);
  iframe.setAttribute('src', iframe.getAttribute('data-url'));
};

/**
 * Stop playing the vidyard video in the iframe.
 * @param ref
 *  Object reference created with useRef
 */
export const stopVidyardVideo = ref => {
  const iframe = getIframe(ref);
  iframe.removeAttribute('src');
};

/**
 * Add the &autoplay=1 arg to the end of the videos.
 */
export const addAutoplayArgToEmbedCode = embedCode =>
  embedCode.replace(/(?:src="(.+?)")/, 'src="$1&autoplay=1"');
